import { ethers } from "ethers";
import QuirkyHamsters from "../../chain_info/QuirkyHamsters.json";

export const load = async () => {
    const contract = await loadContract();
    const signer = await loadSigner();
    const chainId = await loadChainId();
    return {contract, signer, chainId}
}

const loadProvider = async()=>{
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider;
}

const loadChainId = async()=>{
    const provider = await loadProvider();
    const {chainId} = await provider.getNetwork();
    return chainId;
}

const loadSigner = async()=>{
    const provider = await loadProvider()
    const signer = provider.getSigner()
    return signer;
}

const loadContract = async ()=> {
    const provider = await loadProvider()
    const contract_address="0x8E37a0d30EC33CF1F7aF391B7B9316738BfbdcdB"
    const contract_abi=QuirkyHamsters["abi"]
    const contract = new ethers.Contract(contract_address,contract_abi,provider)
    return contract;
}

export const isTransactionMined = async(transactionHash)=>{
    const provider = await loadProvider()
    const txn = await provider.waitForTransaction(transactionHash)
    if (txn!==0 && txn!==null){
        return true
    } else {
        return false
    }
}

export const transformResponse = async(callback)=>{
    const txn = await callback.then(
        (data)=>{
        return data
        },((error)=>{
        try{
            const response = error.toString().split("execution reverted:")[1].split('",')[0] 
            return response
        }catch(error){
            return 'Sorry, something happened. Try again later.\n Maybe you denied the transaction, changed network or you dont have enough funds!'
        }
        }))
    return txn
}

export const MintCountLogic = (initial, max, supply, objeto)=>{
    
    const fragment = document.createDocumentFragment()
    for (let i = initial+1; i<=max && i<=supply; i++){

        const tr = document.createElement('tr');
        const td1 = document.createElement('td');
        const td2 = document.createElement('td');
        const a = document.createElement('a');

        td1.className='HamsterId'
        td1.textContent=i
        td2.className='View'
        a.href = `${process.env.REACT_APP_OPENSEA}${i}.png`
        a.textContent='VIEW OPENSEA'

        td2.appendChild(a)
        tr.appendChild(td1)
        tr.appendChild(td2)

        fragment.appendChild(tr)
        
    }
    objeto.appendChild(fragment)

    setTimeout(()=>{
        if(objeto.childElementCount <= supply){
                setObserver(supply)
            } else { }
    }, 1000)

    return(<></>)
}

const observerOptions = {
    treshold: 0.5
}
export const setObserver = (supply)=>{

    const objeto = document.getElementById('MintCount')
    if(objeto!==null && objeto.childElementCount<=supply){
        let observer = new IntersectionObserver((entries)=>{
            entries.forEach(entry => {
                if(entry.isIntersecting===true){
                    observer.unobserve(entry.target)
                    
                    setTimeout(()=>{
                        const initial=objeto.childElementCount
                        const max=initial+parseInt(process.env.REACT_APP_INCREMENT)
                        if((entry.target===objeto && initial===0) || (entry.target===objeto.lastElementChild && initial>0)){
                            MintCountLogic(initial, max, supply, objeto)
                        }
                    },1)
                    
                }else{}
            })}, observerOptions)
            
        setTimeout(()=>{
            observer.observe(objeto.lastElementChild==null?objeto:objeto.lastElementChild)
        },1000)
        
    } else {}
}
