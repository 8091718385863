import {useEthers} from '@usedapp/core';

export const ConnectWallet = ()=>{
    const {account, activateBrowserWallet} = useEthers()

    const isConnected=account!==undefined
    return(
        <div>
            {!isConnected? 
                (    <button color="primary" className='connect' onClick={()=>activateBrowserWallet()}>
                        Connect To MetaMask!
                    </button>
                ):(<></>)
            }
        </div>
    )
}

