import React from 'react'
// import etherscanLogo from '../imgs/etherscan_logo.png'
import polygonLogo from '../imgs/polygonscan_logo.png'
import openseaLogo from '../imgs/opensea_logo.png'
import text_hamster from '../imgs/text_hamsters.png'
export const Header = () => {
  return (
    <div className='header'>
        <div className='div_logo_text'>
          <a href="http://localhost:3000/" ><img src={text_hamster} alt='Quirky Hamsters' className='logo_text'></img></a>
        </div>
        <div className='socialMedia'>
            <a href={process.env.REACT_APP_POLYGOSCAN_URL} target='_blank' rel='noreferrer'><img src={polygonLogo} className='social' alt='Quirky Hamsters'></img></a>
            <a href={process.env.REACT_APP_OPENSEA} target='_blank' rel='noreferrer'><img src={openseaLogo} className='social'  alt='OpenSea Quirky Hamsters'></img></a>
        </div>
    </div>
  )
}
