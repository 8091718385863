import React from 'react'
import { useState } from 'react';
import { load } from './Funciones';

export const ContractFunctions = () => {
    const [contract, setContract] = useState('');
    const [signer, setSigner] = useState({});
    const [refresh, setRefresh] = useState(true);
  
    React.useEffect(()=>{
        if(!refresh) return ;
        setRefresh(false);
        load().then((e)=>{
            setContract(e.contract);
            setSigner(e.signer);
        });
    }, [refresh])

    const getPrice = async()=>{
        const price= await contract.getHamsterPrice()
        return price
    }

    const mintHamster = async(amount)=>{
        const contractSigner = contract.connect(signer)
        const price=await getPrice();
        const ethAmount=price.mul(amount);
        return contractSigner.mintHamster(amount, {value:ethAmount})
    }

    const getTotalSupply=async()=>{
        if (typeof(contract)!='string'){
            const supply = await contract.totalSupply()
            return supply.toString()
        } else {return 0}
    }

    return {mintHamster, getTotalSupply}
}

