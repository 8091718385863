import React from "react";
import { useState } from "react";
import { load } from "./Functioning/Funciones";
import { MintCountTable } from "./MintCount/MintCountTable";

export const ChangeChain = ({ supply }) => {
  const [chainId, setChainId] = useState(0);
  const [refresh, setRefresh] = useState(true);

  React.useEffect(() => {
    if (!refresh) return;
    setRefresh(false);
    load().then((e) => {
      setChainId(e.chainId);
    });
  }, [refresh]);

  return (
    <div className="changeChain">
      {chainId.toString() !== process.env.REACT_APP_CHAIN_ID.toString() ? (
        <div>
          <p>
            Right now you are not connected to the Polygon Mainnet, which is
            required for the minting process.
          </p>
          <br></br>
          <p>
            Change the network in you wallet, or if you need to add it, you can
            do it
            <a
              href="https://chainlist.org/chain/137"
              rel="noreferrer"
              target="_blank"
            >
              <span className="View">here. </span>
            </a>
            Then refresh and it'll be all ready to mint some of these Quirky
            Hamsters.
          </p>
        </div>
      ) : (
        <MintCountTable supply={supply}></MintCountTable>
      )}
    </div>
  );
};
