import React from 'react';
import Secret from '../../imgs/Gif_Hamsters.gif' 
// import { Observer } from './Observer';

export const MintCountTable = ({supply})=>{
    
    return(
        <div className='MintCountDiv'>
            <h2>Mint Count: {supply}/5050</h2>
            <img src={Secret} alt='Quirky Hamsters' className='justMedia secret'></img>
            {/* <table>
                <thead key={0}>
                    <tr>
                        <th>ID</th>
                        <th>OpenSea</th>
                    </tr>
                </thead>
                <tbody id='MintCount'>
                </tbody>
            </table> */}

          {/* <Observer supply={supply}></Observer> */}

        </div>
    )
}

