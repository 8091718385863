import { Main } from './components/Main';
import './App.css';
import { DAppProvider } from '@usedapp/core';
import { Header } from './components/Header';
import { useEffect } from 'react';

function App() {
  const config={}
  
  useEffect(()=>{
    window.history.scrollRestoration = 'manual'
  },[])

  return (
    <div className="App">
      <DAppProvider config={config}>
        <Header></Header>
        <Main></Main>
      </DAppProvider>
    </div>
  );

}

export default App;
