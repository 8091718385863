import React , { useState, useEffect } from 'react';
import Secret from '../imgs/Gif_Hamsters.gif' 
import {useEthers} from '@usedapp/core';
import {ConnectWallet} from './ConnectWallet';
import { ContractFunctions } from './Functioning/ContractFunctions';
import {Alert, CircularProgress, Snackbar} from "@mui/material";
import { isTransactionMined, transformResponse } from './Functioning/Funciones';
// import {MintCountTable} from './MintCount/MintCountTable';
import { ChangeChain } from './ChangeChain';
export const Main = () => {

  const [amount, setAmount] = useState(1);
  const [showMintStatus, setShowMintStatus]=useState(false);
  const [message, setMessage]=useState(false);
  const [severity, setSeverity]=useState("info");
  const [mining, setMining] = useState(false);
  
  const {account} = useEthers();
  const {mintHamster, getTotalSupply} = ContractFunctions();

  const [supply, setSupply]=useState(1)

  const updateTotalSupply = async()=>{
      setSupply(await getTotalSupply().then(data=>{return data}))
  }

  const handleStateSubmit= async ()=>{ 
    setMining(true)
    const txn = await transformResponse(mintHamster(amount))
    if (typeof(txn) !== 'string'){
      const response = await isTransactionMined(txn.hash)
      if(response){
        setMessage(`Successfully ${amount} Quirky Hamsters minted!`)
        setSeverity("success")
        updateTotalSupply()
      }else{
        setMessage(`Transaction fail. Try again later`)
        setSeverity("error") 
      }
    }else{
      setMessage(txn)
      setSeverity("error")
    }
    setMining(false)
    setShowMintStatus(true)
  }

  const handleCloseSnack = ()=>{
    setShowMintStatus(false)
  }

  useEffect(()=>{
    updateTotalSupply()
  })

  return (
    <div className='all'>
      <div className='primer'>
        <h1>QUIRKY <br></br>HAMSTERS</h1>
        
        <div>
          <p>These hamsters aren't normal, there's something wrong with them. <br></br>
          The army of Quirky Hamsters aren't really the cute hamsters they look like <br></br>
          They have arrived from another planet and now they are looking to conquer the Earth <br></br> 
          If they manage to escape, our existence will be in danger, we need heros that can <br></br>
          keep this hamsters at bay... Are you one of these heros?
          </p>

        </div>
        <h2 className='justMedia'>MORE THAN 5,000 NTFS</h2>
        <h2>NOW ON SALE!!</h2>

        <div className='mintDiv'>
          
          {account===undefined?
            (
              <div>
                <p>Connect to MetaMask so you can mint hamsters</p>
                <ConnectWallet></ConnectWallet>
              </div>
            )
            :
            (
              <div>
                <p>
                Each quirky hamster has been generated from unique layers. No one  <br></br>
                is the same as another other than in their specie, or not even that  <br></br>
                since we have fire, water, radioactive and more types of hamsters!!!
                <br></br><br></br>
                Dont lose this opportunity!
                <br></br><br></br>
              </p>
                <input type="range" min="1" max="25" value={amount} steps="1" onChange={e => setAmount(e.target.value)}></input>
                <button onClick={()=>{handleStateSubmit()}} disabled={mining}>{mining? <CircularProgress size={26}/> : `Mint ${amount} Hamsters`}</button>
              </div>
            )
          }
        </div>
        <br></br>
        <ChangeChain supply={supply}></ChangeChain>
    </div>

      <div className='segundo'>
        <h2>MORE THAN <br></br> 5,000 NTFS</h2>
        <img src={Secret} className='secret' alt='Quirky Hamsters'></img>
      </div>    

      <Snackbar open={showMintStatus} autoHideDuration={5000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severity}>{message}</Alert>
      </Snackbar>

    </div>


)
}
